const routes = {
  reCaptchaVerify: 'system/recaptcha',
  uploadDocuments: 'system/uploadDocuments',
  generateSessionID: 'system/generateSessionID',
  getBankDetails: 'system/bankDetails',
  getBusinessRegions: 'system/businessRegions',
  getCurrencies: 'system/currencies',
  getCountries: 'system/countries',
  getStates: 'system/states',
  getCities: 'system/cities',
  getMakes: 'system/makes',
  createMake: 'system/makes/add',
  getModels: 'system/models',
  createModel: 'system/models/add',
  getColors: 'system/colors',
  createColor: 'system/colors/add',
  getShippingLines: 'system/shippingLines',
  createShippingLine: 'system/shippingLines/add',
  getShippingVendors: 'system/shippingVendors',
  createShippingVendor: 'system/shippingVendors/add',
  getGalaxyYards: 'system/galaxyYards',
  createGalaxyYard: 'system/galaxyYards/add',
  getClearers: 'system/clearers',
  createClearer: 'system/clearers/add',
  getVehicleTowers: 'system/vehTowers',
  createVehicleTower: 'system/vehTowers/add',
  getContainerSizes: 'system/containerSizes',
  createContainerSize: 'system/containerSizes/add',
  getServiceProviders: 'system/serviceProvider',
  createServiceProvider: 'system/serviceProvider/add',
  getDestinations: 'system/destinations',
  createDestination: 'system/destinations/add',
  getBusinessCountries: 'system/business/countries',
  getBusinessLocation: 'system/business/locations',
  getLoadingPorts: 'system/loadingPorts',
  getEmployeeDepartments: 'system/employeeDepartments',
  createEmployeeDepartment: 'system/employeeDepartments/add',
  handleExternalData:'system/extractVinDetail',
  getWarehouses:'system/warehouses',
  getNotifications:'users/notifications',
  getNotificationsCount:'users/notifications/count',
  getBranches:'system/branches',
  createBranch:'system/branches/add',
  getWebTokens:'system/generateMetabaseTokens',
  getFinalDestinations:'system/exportFinalDestinations',
  createFinalDestination:'system/exportFinalDestination/add',
  deleteFinalDestination:'system/exportFinalDestination/delete',
  notificationSeen:'users/notifications/read',
  getSubCustomerPermissions:'users/subCustomers/permissions',
  ApplyPermissions:'users/subCustomers/updatePermissions',
  getPickupLocations:'system/pickupLocations',
  createPickupLocation:'system/pickupLocations/add',
  sendPdfToWhatsApp: 'system/forwardToWhatsApp',
  sendPdfToEmail: 'system/forwardToEmail'

};

export default routes