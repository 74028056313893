import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Grid,
    Box,
    Typography,
    InputAdornment,
} from "@mui/material";
import { useForm } from "react-hook-form";
import InputField from "components/Input";
import DatePicker from "components/DatePicker";
import SelectField from "components/Select";
import InputPhone from "components/InputPhone";
import UploadFile from "components/UploadFile";
import Uploading from "components/Uploading";
import { CleanTypes, Debounce, getFileSize, } from "utils";
import { v4 as uuidv4 } from 'uuid';
import instance from 'config/axios';
import routes from 'services/System/routes';
import { ErrorToaster, SuccessToaster } from "components/Toaster";
import { SearchIcon } from "assets";
import { PrimaryButton } from "components/Buttons";
import GatePassServices from "services/GatePass";
import Colors from "assets/Style/Colors";
import moment from "moment";
import { useAuth } from "context/UseContext";
import UploadedFile from "components/UploadedFile";

const InternalGPModal = ({ open, onClose, initialValues, galaxyYards }) => {
    const { register, handleSubmit, setValue, getValues, control, reset, formState: { errors } } = useForm({
        defaultValues: {
            date: null,
            driverName: "",
            gatePassDate: null,
            vin: "",
            lot: "",
            fromYard: null,
            toYard: null,
            picture: "",
            eidText: "",
            comment: "",
            createdBy: "",
            driverPhone: "",
        },
    });
    const { user } = useAuth();
    const [documentDetail, setDocumentDetail] = useState([]);
    const [documentDetailEid, setDocumentDetailEid] = useState([]);
    const [date, setDate] = useState(new Date());
    const [gpDate, setgpDate] = useState(new Date());
    const [uploadedSize, setUploadedSize] = useState(0);
    const [progress, setProgress] = useState(0);
    const [uploadedSizeEid, setUploadedSizeEid] = useState(0);
    const [progressEid, setProgressEid] = useState(0);
    const [intCode, setIntCode] = useState();
    const [totalCount, setTotalCount] = useState(0);
    const [pageLimit, setPageLimit] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const [vinDetail, setVinDetail] = useState()
    const [filters, setFilters] = useState({});
    const [gpLink, setGpLink] = useState('')
    const [eidLink, setEidLink] = useState('')
    const [selecteToYard, setSelectedToYard] = useState('')
    const [selecteFromYard, setSelectedFromYard] = useState('')
    const [loading, setLoading] = useState(false)
    const allowFilesType = [
        'image/png',
        'image/jpg',
        'image/jpeg',
        'application/pdf',
    ];
    // Set initial values for editing
    //   useEffect(() => {
    //     if (initialValues) {
    //       reset(initialValues);
    //       setDocumentDetail(initialValues?.documents || []);
    //     }
    //   }, [initialValues, reset]);
    useEffect(() => {
        if (initialValues) {
            // Prefill the form for editing
            reset({
                gatePassDate: initialValues.gp_date ? new Date(initialValues.gp_date) : null,
                driverName: initialValues.driver_name,
                vin: initialValues.vin,
                lot: initialValues.lot,
                comment: initialValues.comment,
                fromYard: initialValues.from_yard.name,
                toYard: initialValues.to_yard.name,
                driverPhone: initialValues.driver_phone,
                createdBy: initialValues.creator.name,
            });
            setGpLink(initialValues.key_picture);
            setEidLink(initialValues.eid_picture);
            setgpDate(initialValues.gp_date ? new Date(initialValues.gp_date) : new Date());
            setSelectedToYard(initialValues.to_yard);
            setSelectedFromYard(initialValues.from_yard);
        } else {
            // Reset the form for creating a new gate pass
            reset({
                gatePassDate: null,
                driverName: "",
                vin: "",
                lot: "",
                comment: "",
                fromYard: null,
                toYard: null,
                driverPhone: "",
                createdBy: user?.name,
            });
            setGpLink('');
            setEidLink('');
            setgpDate(new Date());
            setSelectedToYard(null);
            setSelectedFromYard(null);
        }
    }, [initialValues, reset]);

    // File Upload Handlers
    const onSubmit = async (data) => {
        try {
            const payload = {
                gp_date: moment(new Date(gpDate)).format('YYYY-MM-DD'),
                from_yard_id: data?.fromYard?.id,
                to_yard_id: data?.toYard?.id,
                vin: data?.vin,
                lot: data?.lot,
                driver_name: data?.driverName,
                comment: data?.comment,
                key_picture: gpLink,
                eid_picture: eidLink,
                driver_phone: data?.driverPhone,
                booking_id: vinDetail?.booking?.id,
                vehicle_id: vinDetail?.id
            };
            let result;
            if (initialValues?.id) {
                // Update gate pass
                setLoading(true)
                result = await GatePassServices.updateInternalGP(initialValues.id, payload);
                setLoading(false)
            } else {
                // Create new gate pass
                setLoading(true)
                result = await GatePassServices.createInternalGatepass(payload);
                setLoading(false)
            }
            SuccessToaster(result?.message)
            onClose(); // Close the modal
        } catch (error) {
            ErrorToaster(error);
        }
    };
    const removeDoc = () => {
        try {
            setDocumentDetail([]);
            setGpLink('');
            setValue('scanned', '');
        } catch (error) {
            ErrorToaster(error);
        }
    };

    // *For Upload Document
    const handleUploadDocument = async (e) => {
        try {
            e.preventDefault();
            const file = e.target.files[0];
            let arr = [
                {
                    id: uuidv4(),
                    name: file?.name,
                    file: '',
                    type: file?.type.split('/')[1],
                    size: getFileSize(file.size),
                    isUpload: false,
                },
            ];
            if (allowFilesType.includes(file.type)) {
                setDocumentDetail(arr);
                handleUpload(file, arr);
            } else {
                ErrorToaster(`Only ${CleanTypes(allowFilesType)} formats is supported`);
            }
        } catch (error) {
            ErrorToaster(error);
        }
    };

    const handleUpload = async (file, docs) => {
        setProgress(0);
        try {
            const formData = new FormData();
            formData.append('document', file);
            const { data } = await instance.post(routes.uploadDocuments, formData, {
                onUploadProgress: (progressEvent) => {
                    const uploadedBytes = progressEvent.loaded;
                    const percentCompleted = Math.round(
                        (uploadedBytes * 100) / progressEvent.total
                    );

                    setProgress(percentCompleted);
                    setUploadedSize(getFileSize(uploadedBytes));
                },
            });
            if (data) {
                docs[0].isUpload = true;
                docs[0].file = data?.data?.nations;
                setDocumentDetail(docs);
                setGpLink(data?.data?.nations);
            }
        } catch (error) {
            ErrorToaster(error);
        }
    };
    const removeDocEid = () => {
        try {
            setDocumentDetailEid([]);
            setEidLink('');
            setValue('eid', '');
        } catch (error) {
            ErrorToaster(error);
        }
    };

    // *For Upload Document
    const handleUploadDocumentEid = async (e) => {
        try {
            e.preventDefault();
            const file = e.target.files[0];
            let arr = [
                {
                    id: uuidv4(),
                    name: file?.name,
                    file: '',
                    type: file?.type.split('/')[1],
                    size: getFileSize(file.size),
                    isUpload: false,
                },
            ];
            if (allowFilesType.includes(file.type)) {
                setDocumentDetailEid(arr);
                handleUploadEid(file, arr);
            } else {
                ErrorToaster(`Only ${CleanTypes(allowFilesType)} formats is supported`);
            }
        } catch (error) {
            ErrorToaster(error);
        }
    };

    const handleUploadEid = async (file, docs) => {
        setProgressEid(0);
        try {
            const formData = new FormData();
            formData.append('document', file);
            const { data } = await instance.post(routes.uploadDocuments, formData, {
                onUploadProgress: (progressEvent) => {
                    const uploadedBytes = progressEvent.loaded;
                    const percentCompleted = Math.round(
                        (uploadedBytes * 100) / progressEvent.total
                    );

                    setProgressEid(percentCompleted);
                    setUploadedSizeEid(getFileSize(uploadedBytes));
                },
            });
            if (data) {
                docs[0].isUpload = true;
                docs[0].file = data?.data?.nations;
                setDocumentDetailEid(docs);
                setEidLink(data?.data?.nations);
            }
        } catch (error) {
            ErrorToaster(error);
        }
    };

    const handleFormSubmit = (data) => {

        onClose(); // Close modal
    };

    const getVinDetail = async (params) => {
        const { data } = await GatePassServices.getVinDetail(params)
        setVinDetail(data?.vehicles?.rows)
        setValue('vin', data?.vehicles?.rows?.booking?.vin)
        setValue('lot', data?.vehicles?.rows?.booking?.lot_number)
    }
    const handleFilter = (data) => {
        Debounce(() => getVinDetail(data));
    }
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>{initialValues ? "Edit Driver Info" : "Add Driver Info"}</DialogTitle>
            <DialogContent>
                <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>

                    <Grid container spacing={2}>
                        {/* Input Fields */}
                        {!initialValues && (
                            <Grid container spacing={0} alignItems={"center"} gap={2}>
                                <Grid item xs={4} sm={4} md={4} mt={2} ml={2}>
                                    <InputField
                                        size={"small"}
                                        inputStyle={{ backgroundColor: '#f5f5f5' }}
                                        label={'Search'}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                        }}
                                        placeholder={"Search"}
                                        register={register("search")}
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} mt={2}>
                                    <Box sx={{ mt: "12px" }}>
                                        <PrimaryButton onClick={() => handleFilter({ search: getValues('search') })} title="Search" />
                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                        {(vinDetail || initialValues) && <>
                            <Grid item xs={12} sm={6}>
                                <InputField
                                    disabled={true}
                                    size="small"
                                    label="Created By"
                                    placeholder="Enter Created By"
                                    register={register("createdBy",

                                        { required: "Created By is required" })}
                                    error={errors?.createdBy?.message}


                                />
                            </Grid>

                            {/* Date Pickers */}

                            <Grid item xs={12} sm={6}>
                                <DatePicker
                                    size="small"
                                    label="Gate Pass Date"
                                    value={gpDate}
                                    onChange={(date) => setValue("gatePassDate", date)}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <InputField
                                    size="small"
                                    disabled={true}
                                    label="VIN"
                                    placeholder="Enter VIN"
                                    register={register("vin", { required: "VIN is required" })}
                                    error={errors?.vin?.message}

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputField
                                    size="small"
                                    label="LOT"
                                    disabled={true}
                                    placeholder="Enter LOT"
                                    register={register("lot", { required: "LOT is required" })}
                                    error={errors?.lot?.message}

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputField
                                    size="small"
                                    label="Comment"
                                    placeholder="Enter Comment"
                                    register={register("comment")}
                                    error={errors?.comment?.message}

                                />
                            </Grid>


                            {/* Select Fields */}
                            <Grid item xs={12} sm={6}>
                                <SelectField
                                    size="small"
                                    label="From Yard"
                                    options={galaxyYards || []} // Fallback to an empty array
                                    selected={selecteFromYard} // Fallback to null
                                    onSelect={(value) => {
                                        setSelectedFromYard(value)
                                        setValue("fromYard", value)
                                    }}
                                    error={errors?.fromYard?.message}

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SelectField
                                    size="small"
                                    label="To Yard"
                                    options={galaxyYards || []} // Replace with options
                                    selected={selecteToYard}
                                    onSelect={(value) => {
                                        setSelectedToYard(value)
                                        setValue("toYard", value)
                                    }}
                                    error={errors?.toYard?.message}

                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputField
                                    size="small"
                                    label="Driver Name"
                                    placeholder="Enter Driver Name"
                                    error={errors?.driverName?.message}
                                    register={register("driverName", { required: "Driver Name is required" })}
                                // value={getValues("driverName")} // Fallback to empty string
                                />
                            </Grid>
                            {/* Phone Input */}
                            <Grid item xs={12} sm={6}>
                                <InputPhone
                                    label={"Driver Number"}
                                    name={"driverPhone"}
                                    control={control}
                                    error={errors?.driverPhone?.message}
                                    onBlur={(e, v) => setIntCode(v.dialCode)}
                                    rules={{
                                        required: "Please enter your international mobile number.",
                                    }}
                                    register={register("driverPhone", {
                                        required: "Please enter number.",
                                    })}
                                />
                            </Grid>

                            {/* Upload Fields */}
                            <Grid item xs={12} sm={8}>
                                <Typography
                                    variant='body1'
                                    sx={{ color: Colors.charcoalGrey, mb: 1 }}
                                >
                                    Gate Pass Document
                                </Typography>
                                <UploadFile
                                    custom={true}
                                    style={{
                                        backgroundColor: '#e7efeb',
                                        width: '100%',
                                        height: '40px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        padding: '20px',
                                        border: '1px dashed #aeb1b0',
                                        borderRadius: '10px',
                                    }}
                                    // accept={allowFilesType}
                                    error={errors?.scanned?.message}
                                    register={register('scanned', {
                                        onChange: (e) => handleUploadDocument(e, 'picture'),
                                    })}
                                />
                            </Grid>
                            {
                                initialValues?.key_picture && documentDetail.length === 0 ? (
                                    <Grid item xs={4} sm={4}>
                                        <Typography variant="body1" sx={{ color: Colors.charcoalGrey }}>
                                            Gate Pass Document
                                        </Typography>
                                        <UploadedFile
                                            data={{
                                                name: "Gate Pass Document",
                                                file: initialValues?.key_picture,
                                            }}
                                        />
                                    </Grid>
                                ) : (
                                    <Grid item xs={4} sm={4}>
                                        {documentDetail.length > 0 && (
                                            <Box sx={{ maxHeight: 300, overflow: "auto", pr: 1 }}>
                                                {documentDetail.map((item, index) => (
                                                    <Uploading
                                                        key={index}
                                                        data={item}
                                                        uploadedSize={uploadedSize}
                                                        progress={progress}
                                                        removeDoc={() => removeDoc()}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                    </Grid>
                                )
                            }



                            <Grid item xs={12} sm={8}>
                                <Typography
                                    variant='body1'
                                    sx={{ color: Colors.charcoalGrey, mb: 1 }}
                                >
                                    EID Document
                                </Typography>
                                <UploadFile
                                    custom={true}
                                    style={{
                                        backgroundColor: '#e7efeb',
                                        width: '100%',
                                        height: '40px',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        padding: '20px',
                                        border: '1px dashed #aeb1b0',
                                        borderRadius: '10px',
                                    }}
                                    // accept={allowFilesType}
                                    error={errors?.eid?.message}
                                    register={register('eid', {
                                        onChange: (e) => handleUploadDocumentEid(e),
                                    })}
                                />
                            </Grid>

                            {/* Uploaded Files */}
                            {
                                initialValues?.eid_picture && documentDetailEid.length === 0 ? (
                                    <Grid item xs={4} sm={4}>
                                        <Typography variant="body1" sx={{ color: Colors.charcoalGrey }}>
                                            EID Document
                                        </Typography>
                                        <UploadedFile
                                            data={{
                                                name: "EID Document",
                                                file: initialValues?.eid_picture,
                                            }}
                                        />
                                    </Grid>
                                ) : (
                                    <Grid item xs={4} sm={4}>
                                        {documentDetailEid.length > 0 && (
                                            <Box sx={{ maxHeight: 300, overflow: "auto", pr: 1 }}>
                                                {documentDetailEid.map((item, index) => (
                                                    <Uploading
                                                        key={index}
                                                        data={item}
                                                        uploadedSize={uploadedSize}
                                                        progress={progress}
                                                        removeDoc={() => removeDoc()}
                                                    />
                                                ))}
                                            </Box>
                                        )}
                                    </Grid>
                                )
                            }
                        </>}
                    </Grid>
                    <DialogActions>
                        <Button onClick={onClose} color="secondary" variant="outlined">
                            Cancel
                        </Button>
                        {(vinDetail || initialValues) &&
                            <PrimaryButton loading={loading} title={initialValues ? "Update" : "Submit"} type="submit" color="primary" variant="contained" />
                        }
                    </DialogActions>
                </Box>


            </DialogContent>
        </Dialog>
    );
};

export default InternalGPModal;
