const routes = {
  getExportCustomers: "/export/customers",
  getVehiclesChecklist: "/export/vehicles/checklist",
  getFinalDestination: "/system/exportFinalDestinations",
  getStatus: "/system/exportContainerStatuses",
  getMake: "/system/makes",
  getModel: "/system/models",
  getColors: "/system/colors",
  CreateExport: "/export/vehicles/add",
  getExportVehicles: "/export/vehicles/",
  getVehiclesVin: "/export/vin",
  getVinDetail: "/export/vin-detail/",
  updateRecovery: "/export/update/recovery",
  getManifest: "/export/vehicles/filters",
  getVehicleExportDetails: "/export/vehicle/details",
  getExportPaymentPreview: "/export/vehicle/preview",
  getVehicleExportPreview: "/export/vehicle/preview",
  AddContainer: "/export/containers/add",
  getExportContainers: "/export/containers",
  UpdateContainer: "/export/containers/update",
  getShipmentDetails: "/export/shipments/",
  getOffloadDestination: "/system/exportOffLoadDestinations",
  UpdateManifest: "/export/vehicles/updateManifest",
  UpdateCMR: "/export/vehicles/updateCmr",
  CreateDamage: "/export/vehicles/updateDamage",
  DeleteDamage: "/export/vehicles/deleteDamage",
  CustomerHandover: "/export/shipment/handover",
  getVehicleStatus: "/system/exportVehicleStatuses",
  VehicleStatusUpdate: "/export/vehicles/update",
  VehicleShipmentUpdate: "/export/vehicles/updateShipmentStatus",
  VehicleBrokerUpdate: "/export/vehicles/update",
  getExportCustomersList: "customers/export",
  createExportTT: "export/tt/add",
  getVendorDropdown: "export/vendors",
  getPaymentAccounts: "export/port/agent",
  getTT: "export/tt",
  getTTDetail: "export/tt/details",
  applyFund: "export/funds/apply",
  getVendorAppliedFunds: "export/funds",
  getVendorCenter: "export/vendors",
  getCountryDropdown: "system/exportCountries",
  getContainerList: "export/containers",
  handlePay: "export/vehicles/payDamage",
  getExportContainersDetails: "export/container/details",
  UpdateContainerCosting: "export/costing/container/update",
  addVehiclePayment: "export/vehicle/pay",
  getVehiclePayments: "export/vehicle/payments",
  getVendorPayments: "export/vehicles/vendorPayments",
  getExportPaymentDetails: "/export/vehicle/payments/details",
  getBorderVins: "export/border/vehicles",
  updateBorderCosting: "export/border/costing/add",
  getBorderCostingVehicles: "export/border/costings",
  getBorderCostingDetail: "export/border/costing/details",
  EditContainer: "export/containers/edit",
  editBorderCosting: "export/border/costing/update",
  getBorderCostingApproval: "export/border/costing/approvals",
  BorderCostingApproval: "export/border/costing/approve",
  addContainerPayment: "export/container/pay",
  getExportContainerPayments: "export/container/payments",
  getExportContainerPaymentDetails: "export/container/payments/details",
  getExportCountries: "system/exportCountries",
  CreateExportCountry: "system/exportCountries/add",
  DeleteExportCountry: "system/exportCountries/delete",
  getTTPreview: "export/tt/preview",
  getExportContainerPaymentPreview: "export/container/payments/preview",
  getExportContainerDetails: "export/container/details",
  getExportContainerPreview: "export/container/preview",
  getExportDropdowns: "export/rates/filters",
  getExportRates: "export/rates",
  updateExportRates: "export/rates/update",
  uploadExportRate: "export/rates/upload",
  AddRates: "export/rates/add",
  addRecoveryAgent: "auth/register/recovery/agent",
  getRecoveryAgentCode: "auth/register/recovery/agent/getCode",
  getRecoveryAgentList: "auth/recovery/agents/list",
  getAgentsList: "auth/recovery/agents",
  getAllExportRecoveries: "export/recovery/soa",
  getRecoveryAgentDetail: "auth/recovery/getAgent",
  getUserDetail:"export/customerById",
  createPackingList:"export/packingList/add",
  getPackingList:"export/packingList",
  updatePackingList:"export/packingList/update",
  reverseVehicleInvoice:"export/vehicle/reverse",
  reverseContainerInvoice:"export/container/reverse",
};

export default routes;
