const routes = {
  getVccVehicles: 'vcc/vehicles',
  declareVcc: 'vcc/declare',
  updateDeclareVcc: 'vcc/vcc/update',
  updateDeclaration: 'vcc/update',
  customVatCharges: 'vcc/customVatCharges',
  getVinsLots: 'vcc/vinsLots',
  sendApproval: 'vcc/sendApproval',
  depositVcc: 'vcc/deposit',
  getVccList: 'vcc',
  approveVcc: 'vcc/approve',
  issueVcc: 'vcc/issue',
  deleteVcc: 'vcc/deIssue',
  deleteDeposit: 'vcc/deleteDeposit',
  getVccRefundedList: 'vcc/refunded',
  getVccRefundedDetail: 'vcc/refunded/details',
  getVccRefundedPreview: 'vcc/refunded/preview',
  getExitPaperReceiving: 'vcc/exitPaperReceivingList',
  receiveExitPaper: 'vcc/receiveExitPaper',
  refundExitPaper: 'vcc/refundExitPaper',
  getVccDepositedList: 'vcc/deposits',
  getVccDepositedDetail: 'vcc/deposits/details',
  getVccDepositedPreview: 'vcc/deposits/preview',
  purposeVcc: 'vcc/updatePurpose',
  getMakasa: 'vcc/makasaList',
  receiveMakasa: 'vcc/receiveMakasa',
  reverseMobaya: 'vcc/mobaya/reverse',
  reverseCEC: 'vcc/cec/reverse',
  getMobaya: 'vcc/mobayaList',
  getMobayaReversals: 'vcc/mobayaReversals',
  getVatDue: 'accounts/vatDues',
  getAuctionHouseDue: 'accounts/auctionHouseDues',
  issueBulkVcc: 'vcc/issue/bulk',
  getVccDownloadedList: 'vcc/downloads',
  getVatUnDue: 'accounts/vatUnDues',
  getCustomDue: 'accounts/customDues',
  getCustomUnDue: 'accounts/customUnDues'
};

export default routes