import {
  Table,
  TableBody,
  TableCell,
  TableRow,
  Box,
  Grid,
  Typography,
  TableContainer,
  TableHead,
  Paper,
} from "@mui/material";
import React from "react";
import ShippingLanscape from "./shipping_short_landscape.svg";
import ShippingPotarit from "./shipping_sjort_potrait.svg";
import CarLanscape from "./used_car_landscape.svg";
import CarPotarit from "./used_car_potrait.svg";
import { FontFamily } from "assets";
import moment from "moment";
import { CommaSeparator } from "utils";
import { useStyles } from "./styles";
import BalanceSheet from "./BalanceSheet";
import TrialBalance from "./TrialBalance";
import AuctionHouse from "./AuctionHouse";
import ClientLedger from "./ClientLedger";
import WalletDashboard from "./WalletDashboard";
import ShippingRA from "./ShippingRA";
import CustomerVehicleLedger from "./CustomerVehicleLedger";
import CustomerShippingLedger from "./CustomerShippingLedger";
import ProfitLossStatement from "./ProfitLossStatement";

const PDF = ({
  invoiceName,
  selectedCustomer,
  mode,
  fromdate,
  todate,
  type,
  selectedStatus,
  tableHead,
  resultData,
  total,
  currencyExchangeRate,
  shippingVault,
  totalVCC,
  vehicleVault,
  allSums,
  mainTotal,
  totalBalance,
  totalPaid,
  dueBreakup,
  totalVehicleAmount,
  otherCharges,
  netBalance,
  total30days,
  total15Days,
  total16Days,
  receivableTotal,
  libalTotal,
  capitalTotal,
  filter,
  netDueTotal
}) => {
  const classes = useStyles();
  const headerImage =
    (mode === "portrait" && invoiceName === "Shipping RA Aging") ||
    (mode === "portrait" && invoiceName === "Shipping Wallet Statement") ||
    (mode === "portrait" && invoiceName === "Shipping Statement Of Account") ||
    (mode === "portrait" && invoiceName === "Shipping RA")
      ? ShippingPotarit
      : (mode === "landscape" && invoiceName === "Shipping RA Aging") ||
        (mode === "landscape" && invoiceName === "Shipping Wallet Statement") ||
        (mode === "landscape" &&
          invoiceName === "Shipping Statement Of Account") ||
        (mode === "landscape" && invoiceName === "Shipping RA")
      ? ShippingLanscape
      : (mode === "landscape" && invoiceName === "VAT DUE Statement") ||
        (mode === "landscape" &&
          invoiceName === "Vehicle Statement Of Account") ||
        (mode === "landscape" && invoiceName === "Vehicle Wallet Statement") ||
        (mode === "landscape" && invoiceName === "Auction House") ||
        (mode === "landscape" && invoiceName === "Vendor Payable Due Report")
      ? CarLanscape
      : (mode === "portrait" && invoiceName === "VAT DUE Statement") ||
        (mode === "portrait" &&
          invoiceName === "Vehicle Statement Of Account") ||
        (mode === "portrait" && invoiceName === "Vehicle Wallet Statement") ||
        (mode === "portrait" && invoiceName === "Auction House") ||
        (mode === "portrait" && invoiceName === "Vendor Payable Due Report")
      ? CarPotarit
      : CarPotarit;

  const today = new Date();
  const currentDate = moment(today).format("DD-MMM-YY HH:mm");
  const startDate = fromdate && moment(fromdate).format("DD-MMM-YY");
  const endDate = todate && moment(todate).format("DD-MMM-YY");
  const status = selectedStatus && selectedStatus?.name;
  let Balance = 0;
  let TotalShippingBalance = 0;
  let TotalVehicleLedgerAmount = 0;
  let TotalVehicleVaultAmount = 0;
  let TotalShippingVaultAmount = 0;
  let TotalShippingLedgerAmount = 0;
  let TotalVccDepositAmount = 0;
  let TotalGatePassAmount = 0;
  let TotalVehicleBalance = 0;
  return (
    <>
      <Box
        sx={{
          minHeight: mode === "landscape" ? "210mm" : "297mm !important",
        }}
      >
        {invoiceName === "Balance Sheet" ||
        invoiceName === "Trial Balance" ||
        invoiceName === "Profit or Loss Statement" ? (
          ""
        ) : (
          <Box
            component="img"
            src={headerImage}
            sx={{
              marginBottom: "0px",
            }}
            width="100%"
            height="80px"
          />
        )}
        <Box className="invoice">
          <Box className="invoice-container">
            <Grid
              container
              className="main-cont"
              spacing={
                invoiceName === "Balance Sheet" ||
                invoiceName === "Trial Balance" ||
                invoiceName === "Profit or Loss Statement"
                  ? 0
                  : 1
              }
              sx={{
                background: "#EFF8E7",
                display: "flex",
                justifyContent: "center",
                // alignItems: "center",
              }}
            >
              {invoiceName === "Balance Sheet" ||
              invoiceName === "Trial Balance" ||
              invoiceName === "Profit or Loss Statement" ? (
                <>
                  <Grid item xs={3}></Grid>
                  <Grid
                    item
                    xs={6}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderBottom: "1px solid black",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: FontFamily.openSans,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "14px !important",
                        fontWeight: 700,
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      Galaxy Used Cars Tr. LLC
                    </Typography>
                  </Grid>
                  <Grid item xs={3}></Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 0,
                      margin: 0,
                    }}
                  ></Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 0,
                      margin: 0,
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: FontFamily.openSans,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "14px !important",
                        fontWeight: 700,
                        padding: 0,
                        margin: 0,
                      }}
                    >
                      {invoiceName}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      padding: 0,
                      margin: 0,
                    }}
                  ></Grid>
                  {/* <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      padding: "0px 8px 0px 0px !important",
                      margin: 0,
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "8px !important",
                        fontFamily: FontFamily.openSans,
                        fontWeight: 700,
                        marginRight: "10px",
                      }}
                    >
                      Generated on:{" "}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "8px !important",
                        fontFamily: FontFamily.openSans,
                        fontWeight: 700,
                      }}
                    >
                      {currentDate}
                    </Typography>
                  </Grid> */}
                  <Grid
                    item
                    xs={8}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      padding: "0px 8px 0px 0px !important",
                      margin: 0,
                    }}
                  ></Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                      padding: "0px 8px 0px 0px !important",
                      margin: 0,
                    }}
                  >
                    <Box>
                      {startDate && (
                        <Table
                          sx={{
                            width: "100%",
                            borderCollapse: "collapse",
                          }}
                        >
                          <TableBody>
                            <TableRow>
                              <TableCell
                                sx={{
                                  borderTop: "1px solid",
                                  borderLeft: "1px solid",
                                  borderRight: "1px solid",
                                  borderTopLeftRadius: "4px",
                                  borderTopRightRadius: "4px",
                                  padding: "2px",
                                  textAlign: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 700,
                                    fontSize: "8px !important",
                                    fontFamily: FontFamily.openSans,
                                  }}
                                >
                                  Statement Period
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{
                                  border: "1px solid",
                                  borderBottomLeftRadius: "4px",
                                  borderBottomRightRadius: "4px",
                                  padding: "2px",
                                  textAlign: "center",
                                }}
                              >
                                <Grid container spacing={1}>
                                  <Grid item xs={12}>
                                    <Box>
                                      <Typography
                                        sx={{
                                          fontWeight: 700,
                                          fontSize: "8px !important",
                                          fontFamily: FontFamily.openSans,
                                          margin: "6px",
                                        }}
                                        component="span"
                                      >
                                        As Of Date:
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontWeight: 700,
                                          fontSize: "8px !important",
                                          fontFamily: FontFamily.openSans,
                                          color: "#5abc0e",
                                        }}
                                        component="span"
                                      >
                                        {startDate}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      )}
                      <Box
                        sx={{
                          padding: "8px",
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "end",
                        }}
                      >
                        <Typography
                          sx={{
                            width: "80px",
                            fontSize: "8px !important",
                            fontFamily: FontFamily.openSans,
                            fontWeight: 700,
                          }}
                          variant="span"
                        >
                          Generated on:{" "}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "8px !important",
                            fontFamily: FontFamily.openSans,
                            fontWeight: 700,
                          }}
                          variant="span"
                        >
                          {currentDate}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "start",
                      textAlign: "start",
                      paddingLeft: "4px",
                    }}
                  >
                    <Box sx={{ paddingLeft: "4px" }}>
                      <Box>
                        <Typography
                          sx={{
                            borderBottom: "1px solid black",
                            fontWeight: 700,
                            marginBottom: "12px",
                            fontFamily: FontFamily.openSans,
                            fontSize: "14px !important",
                          }}
                        >
                          Statement To :-
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          className="client-font"
                          sx={{
                            fontSize: "8px !important",
                            fontWeight: 700,
                            width: "80px",
                            fontFamily: FontFamily.openSans,
                          }}
                        >
                          Client Name:
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "11px !important",
                            textTransform: "capitalize",
                            fontFamily: FontFamily.openSans,
                          }}
                        >
                          {selectedCustomer && selectedCustomer?.name
                            ? selectedCustomer?.name
                            : "N/A"}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "8px !important",
                            fontWeight: 700,
                            width: "80px",
                            fontFamily: FontFamily.openSans,
                          }}
                        >
                          Customer ID:
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "11px !important",
                            textTransform: "capitalize",
                            fontFamily: FontFamily.openSans,
                          }}
                        >
                          {selectedCustomer && selectedCustomer?.ref_id
                            ? selectedCustomer?.ref_id
                            : selectedCustomer?.id
                            ? `C-${selectedCustomer?.id}`
                            : "N/A"}
                        </Typography>
                      </Box>
                      <Box
                        c
                        sx={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "8px !important",
                            fontWeight: 700,
                            width: "80px",
                            fontFamily: FontFamily.openSans,
                          }}
                        >
                          Phone:
                        </Typography>
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "11px !important",
                            textTransform: "capitalize",
                            fontFamily: FontFamily.openSans,
                          }}
                        >
                          {selectedCustomer && selectedCustomer?.uae_phone
                            ? selectedCustomer?.uae_phone
                            : "N/A"}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      alignItems: "start",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: FontFamily.openSans,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "14px !important",
                        fontWeight: 700,
                      }}
                    >
                      {invoiceName}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "end",
                      paddingRight: "4px",
                    }}
                  >
                    <Box>
                      {startDate && endDate && (
                        <Table
                          sx={{
                            width: "100%",
                            borderCollapse: "collapse",
                          }}
                        >
                          <TableBody>
                            {(invoiceName === "Shipping Statement Of Account" ||
                              invoiceName ===
                                "Vehicle Statement Of Account") && (
                              <TableRow>
                                <TableCell
                                  sx={{
                                    borderTop: "1px solid",
                                    borderLeft: "1px solid",
                                    borderRight: "1px solid",
                                    borderTopLeftRadius: "4px",
                                    borderTopRightRadius: "4px",
                                    padding: "2px",
                                    textAlign: "center",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 700,
                                      fontFamily: FontFamily.openSans,
                                      fontSize: "8px !important",
                                      color: "#5abc0e",
                                    }}
                                  >
                                    {status ? status : "All"}{" "}
                                    {invoiceName ===
                                    "Shipping Statement Of Account"
                                      ? "Shipping"
                                      : "Vehicle"}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            )}
                            <TableRow>
                              <TableCell
                                sx={{
                                  borderTop: "1px solid",
                                  borderLeft: "1px solid",
                                  borderRight: "1px solid",
                                  borderTopLeftRadius:
                                    (invoiceName !==
                                      "Shipping Statement Of Account" ||
                                      invoiceName !==
                                        "Vehicle Statement Of Account") &&
                                    "4px",
                                  borderTopRightRadius:
                                    (invoiceName !==
                                      "Shipping Statement Of Account" ||
                                      invoiceName !==
                                        "Vehicle Statement Of Account") &&
                                    "4px",
                                  padding: "2px",
                                  textAlign: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 700,
                                    fontSize: "8px !important",
                                    fontFamily: FontFamily.openSans,
                                  }}
                                >
                                  Statement Period
                                </Typography>
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                sx={{
                                  border: "1px solid",
                                  borderBottomLeftRadius: "4px",
                                  borderBottomRightRadius: "4px",
                                  padding: "2px",
                                  textAlign: "center",
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={6}>
                                    <Box>
                                      <Typography
                                        sx={{
                                          fontWeight: 700,
                                          fontSize: "8px !important",
                                          fontFamily: FontFamily.openSans,
                                          margin: "6px",
                                        }}
                                        component="span"
                                      >
                                        From:
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontWeight: 700,
                                          fontSize: "8px !important",
                                          fontFamily: FontFamily.openSans,
                                          color: "#5abc0e",
                                        }}
                                        component="span"
                                      >
                                        {startDate}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Box>
                                        <Typography
                                          sx={{
                                            fontWeight: 700,
                                            fontSize: "8px !important",
                                            fontFamily: FontFamily.openSans,
                                            margin: "6px",
                                          }}
                                          component="span"
                                        >
                                          To:
                                        </Typography>
                                        <Typography
                                          sx={{
                                            fontWeight: 700,
                                            fontSize: "8px !important",
                                            fontFamily: FontFamily.openSans,
                                            color: "#5abc0e",
                                          }}
                                          component="span"
                                        >
                                          {endDate}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Grid>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      )}
                      <Box
                        sx={{
                          padding: "8px",
                          display: "flex",
                          justifyContent: "end",
                          alignItems: "end",
                        }}
                      >
                        <Typography
                          sx={{
                            width: "80px",
                            fontSize: "8px !important",
                            fontFamily: FontFamily.openSans,
                            fontWeight: 700,
                          }}
                          variant="span"
                        >
                          Generated on:{" "}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "8px !important",
                            fontFamily: FontFamily.openSans,
                            fontWeight: 700,
                          }}
                          variant="span"
                        >
                          {currentDate}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Box>
        <Box>
          <TableContainer
            component={Paper}
            sx={{
              boxShadow: "0px 8px 18px 0px #9B9B9B1A",
              borderRadius: "4px",
              minWidth: "600px",
              minHeight: "297mm",
            }}
            className="table-box"
          >
            <Table>
              <TableHead>
                {invoiceName === "Shipping RA" && (
                  <TableRow>
                    <TableCell colSpan={10}></TableCell>
                    <TableCell
                      colSpan={2}
                      sx={{
                        background: "#5abc0e",
                        whiteSpace: "normal",
                        fontSize:
                          invoiceName === "Balance Sheet"
                            ? "10px !important"
                            : "6px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        color: "white !important",
                        textAlign: "center",
                        margin: "0px 4px !important",
                        padding: "4px 8px !important",
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                      }}
                      className="pdf-table"
                    >
                      <Typography
                        sx={{
                          // whiteSpace: "normal",
                          wordBreak: "break-word",
                          fontSize:
                            invoiceName === "Balance Sheet"
                              ? "10px !important"
                              : "6px !important",
                          fontWeight: 700,
                          fontFamily: FontFamily.openSans,
                          color: "white !important",
                          textAlign: "center",
                        }}
                      >
                        Vehicle On the Way{" "}
                      </Typography>
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      sx={{
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                        background: "#5abc0e",
                        whiteSpace: "normal",
                        fontSize:
                          invoiceName === "Balance Sheet"
                            ? "10px !important"
                            : "6px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        color: "white !important",
                        textAlign: "center",
                        margin: "0px 4px !important",
                        padding: "4px 8px !important",
                      }}
                      className="pdf-table"
                    >
                      <Typography
                        sx={{
                          background: "#5abc0e",
                          wordBreak: "break-word",
                          fontSize:
                            invoiceName === "Balance Sheet"
                              ? "10px !important"
                              : "6px !important",
                          fontWeight: 700,
                          fontFamily: FontFamily.openSans,
                          color: "white !important",
                          textAlign: "center",
                        }}
                      >
                        Vehicle In Galaxy Yard
                      </Typography>
                    </TableCell>
                    <TableCell
                      colSpan={3}
                      sx={{
                        borderTopLeftRadius: "4px",
                        borderTopRightRadius: "4px",
                        background: "#5abc0e",
                        whiteSpace: "normal",
                        fontSize:
                          invoiceName === "Balance Sheet"
                            ? "10px !important"
                            : "6px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        color: "white !important",
                        textAlign: "center",
                        margin: "0px 4px !important",
                        padding: "4px 8px !important",
                      }}
                      className="pdf-table"
                    >
                      <Typography
                        sx={{
                          // whiteSpace: "normal",
                          wordBreak: "break-word",
                          fontSize:
                            invoiceName === "Balance Sheet"
                              ? "10px !important"
                              : "6px !important",
                          fontWeight: 700,
                          fontFamily: FontFamily.openSans,
                          color: "white !important",
                          textAlign: "center",
                        }}
                      >
                        Client Fisibility
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
                <TableRow
                  sx={{
                    background: "#5abc0e",
                  }}
                >
                  {invoiceName === "VAT DUE Statement" ||
                  invoiceName === "Auction House" ||
                  invoiceName === "Shipping Statement Of Account" ? (
                    <TableCell
                      sx={{
                        whiteSpace: "normal",
                        fontSize:
                          invoiceName === "Balance Sheet"
                            ? "10px !important"
                            : "6px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        color: "white !important",
                        textAlign: "center",
                        margin: "0px 4px !important",
                        padding: "4px 8px !important",
                      }}
                      className="pdf-table"
                    >
                      <Typography
                        sx={{
                          // whiteSpace: "normal",
                          wordBreak: "break-word",
                          fontSize:
                            invoiceName === "Balance Sheet"
                              ? "10px !important"
                              : "6px !important",
                          fontWeight: 700,
                          fontFamily: FontFamily.openSans,
                          color: "white !important",
                          textAlign: "center",
                        }}
                      >
                        {"Sr.No"}
                      </Typography>
                    </TableCell>
                  ) : (
                    ""
                  )}
                  {tableHead.map((item, index) => (
                    <>
                      <TableCell
                        colSpan={
                          (item === "VIN" &&
                            invoiceName === "VAT DUE Statement") ||
                          (item === "Make" &&
                            invoiceName === "VAT DUE Statement") ||
                          item === "VIN" ||
                          item === "Description" ||
                          item === "Comments" ||
                          item === "No. Of Vehicle Shipping Unpaid" ||
                          (item === "Model" &&
                            invoiceName === "Vehicle Statement Of Account") ||
                          (item === "LOT#" &&
                            invoiceName === "Vendor Payable Due Report")
                            ? 2
                            : item === "VIN#" &&
                              invoiceName === "Shipping Statement Of Account"
                            ? 4
                            : (item === "Arrived Date" &&
                                invoiceName ===
                                  "Shipping Statement Of Account") ||
                              (item === "Make" &&
                                invoiceName ===
                                  "Shipping Statement Of Account") ||
                              (item === "Buy Date" &&
                                invoiceName ===
                                  "Shipping Statement Of Account") ||
                              (item === "LOT#" &&
                                invoiceName ===
                                  "Shipping Statement Of Account") ||
                              (item === "Past Due Days" &&
                                invoiceName ===
                                  "Shipping Statement Of Account") ||
                              (item === "Total" &&
                                invoiceName ===
                                  "Shipping Statement Of Account") ||
                              (item === "Paid" &&
                                invoiceName ===
                                  "Shipping Statement Of Account") ||
                              (item === "Balance" &&
                                invoiceName ===
                                  "Shipping Statement Of Account") ||
                              item === "NO. Of Vehicle Shipping Unpaid"
                            ? 2
                            : item === "Vehicle Vault Balance (USD)" ||
                              item === "Vehicle Vault Balance (AED)" ||
                              item === "Shipping Vault Balance (USD)" ||
                              item === "Shipping Vault Balance (AED)" ||
                              item === "Vehicle Ledger Balance (USD)" ||
                              item === "Shipping Ledger Balance (USD)" ||
                              item === "VCC Deposit Balance (USD)" ||
                              item === "Gate Pass (Parking & Recovery) (USD)" ||
                              (item === "Vault ID" &&
                                invoiceName === "Wallet Dashboard") ||
                              item === "Gate Pass (Parking & Recovery) (USD)" ||
                              (item === "Customer Name" &&
                                invoiceName === "Wallet Dashboard") ||
                              (item === "Nationality" &&
                                invoiceName === "Shipping RA")
                            ? 2
                            : 1
                        }
                        sx={{
                          // whiteSpace: "normal",
                          fontSize:
                            invoiceName === "Balance Sheet"
                              ? "10px !important"
                              : "6px !important",
                          fontWeight: 700,
                          fontFamily: FontFamily.openSans,
                          color: "white !important",
                          textAlign: "center",
                          margin: "0px 4px !important",
                          padding: "4px 8px !important",
                        }}
                        key={index}
                      >
                        <Typography
                          sx={{
                            // whiteSpace: "normal",
                            fontSize:
                              invoiceName === "Balance Sheet"
                                ? "10px !important"
                                : "6px !important",
                            fontWeight: 700,
                            fontFamily: FontFamily.openSans,
                            color: "white !important",
                            textAlign: "center",
                          }}
                        >
                          {item}
                        </Typography>
                      </TableCell>
                    </>
                  ))}
                </TableRow>
              </TableHead>
              {invoiceName === "VAT DUE Statement" ? (
                <TableBody>
                  {resultData?.map((item, index) => (
                    <>
                      <TableRow
                        sx={{
                          fontSize: "8px !important",
                          background: index % 2 !== 0 ? "#EFF8E7" : "",
                        }}
                      >
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 700,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.customer_id}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.customer?.name}
                        </TableCell>{" "}
                        <TableCell
                          colSpan={2}
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 700,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                        >
                          {item?.vin ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.lot_number ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          colSpan={2}
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.veh_make?.name ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.veh_model?.name ?? "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.color ?? "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.vcc?.vat_charges_aed).toFixed(2)
                          ) ?? "-"}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              ) : invoiceName === "Auction House" ? (
                <AuctionHouse resultData={resultData} />
              ) : invoiceName === "Vendor Payable Due Report" ? (
                <TableBody>
                  {resultData?.map((item, index) => (
                    <>
                      <TableRow
                        sx={{
                          fontSize: "8px !important",
                          background: index % 2 !== 0 ? "#EFF8E7" : "",
                        }}
                      >
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.booking?.purchase_date
                            ? moment(item?.booking?.purchase_date).format(
                                "DD-MMM-YY"
                              )
                            : "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.booking?.veh_model?.name ?? "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.booking?.veh_make?.name ?? "-"}
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.booking?.vin ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.booking?.lot_number ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.booking?.color ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.shipping?.loading_port?.name ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.shipping?.container_no ?? "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.vehicle?.arrived_galaxy_date
                            ? moment(item?.vehicle?.arrived_galaxy_date).format(
                                "DD-MMM-YY"
                              )
                            : "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {selectedCustomer?.type === "shipping"
                            ? item?.shipping_vendor_total
                            : selectedCustomer?.type === "towing"
                            ? item?.towing_vendor_total
                            : item?.clearance_vendor_total}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {selectedCustomer?.type === "shipping"
                            ? item?.shipping_vendor_paid
                            : selectedCustomer?.type === "towing"
                            ? item?.towing_vendor_paid
                            : item?.clearance_vendor_paid}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {CommaSeparator(
                            parseFloat(
                              selectedCustomer?.type === "shipping"
                                ? item?.shipping_vendor_total
                                : selectedCustomer?.type === "towing"
                                ? item?.towing_vendor_total
                                : item?.clearance_vendor_total
                            ) -
                              parseFloat(
                                selectedCustomer?.type === "shipping"
                                  ? item?.shipping_vendor_paid
                                  : selectedCustomer?.type === "towing"
                                  ? item?.towing_vendor_paid
                                  : item?.clearance_vendor_paid
                              )
                          )}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              ) : invoiceName === "Shipping Statement Of Account" ? (
                <TableBody>
                  {resultData?.map((item, index) => (
                    <>
                      <TableRow
                        sx={{
                          fontSize: "8px !important",
                          background: index % 2 !== 0 ? "#EFF8E7" : "",
                        }}
                      >
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {moment(item?.booking?.purchase_date).format(
                            "DD-MMM-YY"
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.booking?.veh_model?.name ?? "-"}
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.booking?.veh_make?.name ?? "-"}
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.booking?.lot_number ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          colSpan={4}
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.booking?.vin ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.booking?.color ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.vehicle?.vcc ? "Yes" : "No" ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.invoice && item?.invoice?.paid === 0
                            ? "Unpaid"
                            : item?.invoice &&
                              item?.invoice?.paid > 0 &&
                              item?.invoice?.paid < item?.invoice?.total
                            ? "Partial"
                            : "Paid"}
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.vehicle?.arrived_port_date
                            ? moment(item?.vehicle?.arrived_port_date).format(
                                "DD-MMM-YY"
                              )
                            : "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.shipping_charges).toFixed(2)
                          ) ?? "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.late_fee).toFixed(2)
                          ) ?? "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.storage).toFixed(2)
                          ) ?? "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.category_a).toFixed(2)
                          ) ?? "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.broker_fee).toFixed(2)
                          ) ?? "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.title_fee).toFixed(2)
                          ) ?? "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.other_charge).toFixed(2)
                          ) ?? "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(
                              item?.vehicle?.vcc?.custom_due
                                ? item?.custom_duty === 0
                                  ? item?.vehicle?.vcc?.custom_charges_aed /
                                    currencyExchangeRate
                                  : item?.custom_duty
                                : 0
                            ).toFixed(2)
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(
                              item?.vehicle?.vcc?.vat_due
                                ? item?.vat === 0
                                  ? item?.vehicle?.vcc?.vat_charges_aed /
                                    currencyExchangeRate
                                  : item?.vat
                                : 0
                            ).toFixed(2)
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            (
                              parseFloat(item?.shipping_charges) +
                              parseFloat(item?.late_fee) +
                              parseFloat(item?.storage) +
                              parseFloat(item?.category_a) +
                              parseFloat(item?.broker_fee) +
                              parseFloat(item?.title_fee) +
                              parseFloat(item?.other_charge)
                            ).toFixed(2)
                          ) ?? "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.discount).toFixed(2)
                          ) ?? "-"}
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.invoice?.total).toFixed(2)
                          ) ?? "-"}
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.invoice?.paid).toFixed(2)
                          )}
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.invoice?.balance).toFixed(2)
                          )}
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {moment().diff(item?.createdAt, "days")} Days
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                  <TableRow>
                    <TableCell
                      sx={{
                        whiteSpace: "normal",
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        margin: "0px 4px !important",
                        padding: "4px 8px !important",
                        textAlign: "center",
                      }}
                      colSpan={tableHead?.length - 1}
                    >
                      Total:
                    </TableCell>
                    <TableCell colSpan={5}></TableCell>
                    <TableCell
                      colSpan={2}
                      sx={{
                        whiteSpace: "normal",
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        margin: "0px 4px !important",
                        padding: "4px 8px !important",
                        textAlign: "center",
                      }}
                    >
                      ${CommaSeparator(parseFloat(mainTotal).toFixed(2))}
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      sx={{
                        whiteSpace: "normal",
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        margin: "0px 4px !important",
                        padding: "4px 8px !important",
                        textAlign: "center",
                      }}
                    >
                      ${CommaSeparator(parseFloat(totalPaid).toFixed(2))}
                    </TableCell>
                    <TableCell
                      colSpan={2}
                      sx={{
                        whiteSpace: "normal",
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        margin: "0px 4px !important",
                        padding: "4px 8px !important",
                        textAlign: "center",
                      }}
                    >
                      ${CommaSeparator(parseFloat(totalBalance).toFixed(2))}
                    </TableCell>
                    <TableCell colSpan={2}></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={tableHead?.length - 1}
                      sx={{
                        whiteSpace: "normal",
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        margin: "0px 4px !important",
                        padding: "4px 8px !important",
                        textAlign: "center",
                      }}
                    >
                      Net Due Total:
                    </TableCell>
                    <TableCell colSpan={7}></TableCell>
                    <TableCell
                      colSpan={2}
                      sx={{
                        whiteSpace: "normal",
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        margin: "0px 4px !important",
                        padding: "4px 8px !important",
                        textAlign: "center",
                      }}
                    >
                      ${CommaSeparator(netDueTotal)}
                    </TableCell>
                    <TableCell colSpan={4}></TableCell>
                  </TableRow>
                </TableBody>
              ) : invoiceName === "Vehicle Statement Of Account" ? (
                <TableBody
                  className={classes.keeptogether}
                  sx={{
                    pageBreakInside: "avoid",
                  }}
                >
                  {resultData?.map((item, index) => (
                    <>
                      <TableRow
                        sx={{
                          fontSize: "8px !important",
                          background: index % 2 !== 0 ? "#EFF8E7" : "",
                        }}
                      >
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.booking?.buyer?.name || ""}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {moment(item?.created_at).format("DD-MM-YY") || ""}
                        </TableCell>
                        <TableCell
                          colSpan={2}
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.booking?.veh_model?.name || ""}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.booking?.veh_make?.name || ""}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.booking?.lot_number || ""}
                        </TableCell>{" "}
                        <TableCell
                          colSpan={2}
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.booking?.vin || ""}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.booking?.color || ""}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.booking?.value).toFixed(2)
                          ) || ""}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.booking?.other_charges).toFixed(2)
                          ) || ""}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(parseFloat(item?.paid).toFixed(2)) ||
                            ""}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.balance).toFixed(2)
                          ) || ""}
                        </TableCell>
                        <TableCell
                          sx={{
                            whiteSpace: "normal",
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {moment().diff(item?.created_at, "days") || ""} Days
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                  <TableRow sx={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 103, gap:1 }}>
                    <TableCell
                      sx={{
                        whiteSpace: "normal",
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        margin: 0,
                        padding: "4px 0px 4px 4px !important",
                        textAlign: "center",
                      }}
                      colSpan={tableHead?.length - 5}
                    >
                      Total:
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "normal",
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        margin: 0,
                        padding: "4px 0px 4px 4px !important",
                      }}
                    >
                      $
                      {CommaSeparator(
                        parseFloat(totalVehicleAmount).toFixed(2)
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "normal",
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        margin: 0,
                        padding: "4px 0px 4px 4px !important",
                      }}
                    >
                      ${CommaSeparator(parseFloat(otherCharges).toFixed(2))}
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "normal",
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        margin: 0,
                        padding: "4px 0px 4px 4px !important",
                      }}
                    >
                      ${CommaSeparator(parseFloat(totalPaid).toFixed(2))}
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "normal",
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        margin: 0,
                        padding: "4px 0px 4px 4px !important",
                      }}
                    >
                      ${CommaSeparator(parseFloat(totalBalance).toFixed(2))}
                    </TableCell>
                    <TableCell colSpan={3}></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      colSpan={tableHead?.length - 5}
                      sx={{
                        whiteSpace: "normal",
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        margin: 0,
                        textAlign: "center",
                        padding: "4px 0px 4px 4px !important",
                      }}
                    >
                      Net Due Total:
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "normal",
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        margin: 0,
                        padding: "4px 0px 4px 4px !important",
                      }}
                    >
                      ${CommaSeparator(netDueTotal)}
                    </TableCell>
                    <TableCell colSpan={6}></TableCell>
                  </TableRow>
                </TableBody>
              ) : invoiceName === "Vehicle Wallet Statement" ? (
                <TableBody>
                  {resultData?.map((item, index) => (
                    <>
                      <TableRow
                        sx={{
                          fontSize: "8px !important",
                          background: index % 2 !== 0 ? "#EFF8E7" : "",
                        }}
                      >
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.created_at
                            ? moment(item?.created_at).format("DD/MM/YY")
                            : "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.journal_id
                            ? item?.series_id + item?.journal_id
                            : "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.entry?.reference_no ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.type?.type_name ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          colSpan={2}
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            wordBreak: "break-word",
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.description ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          colSpan={2}
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            wordBreak: "break-word",
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.comment ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.debit_cur).toFixed(2)
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.credit_cur).toFixed(2)
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            (Balance += parseFloat(
                              item?.account?.nature === "credit"
                                ? (
                                    parseFloat(item?.credit_cur).toFixed(2) -
                                    parseFloat(item?.debit_cur).toFixed(2)
                                  ).toFixed(2)
                                : (
                                    parseFloat(item?.debit_cur).toFixed(2) -
                                    parseFloat(item?.credit_cur).toFixed(2)
                                  ).toFixed(2)
                            )).toFixed(2)
                          )}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              ) : invoiceName === "Shipping Wallet Statement" ? (
                <TableBody>
                  {resultData?.map((item, index) => (
                    <>
                      <TableRow
                        sx={{
                          fontSize: "8px !important",
                          background: index % 2 !== 0 ? "#EFF8E7" : "",
                        }}
                      >
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.created_at
                            ? moment(item?.created_at).format("DD/MM/YY")
                            : "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.journal_id
                            ? item?.series_id + item?.journal_id
                            : "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.entry?.reference_no ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.type?.type_name ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          colSpan={2}
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            wordBreak: "break-word",
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.description ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          colSpan={2}
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            wordBreak: "break-word",
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.comment ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.debit_cur).toFixed(2)
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.credit_cur).toFixed(2)
                          )}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            item?.account?.nature === "credit"
                              ? (
                                  parseFloat(item?.credit_cur) -
                                  parseFloat(item?.debit_cur)
                                ).toFixed(2)
                              : (
                                  parseFloat(item?.debit_cur) -
                                  parseFloat(item?.credit_cur)
                                ).toFixed(2)
                          )}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              ) : invoiceName === "Shipping RA Aging" ? (
                <TableBody>
                  {resultData?.map((item, index) => (
                    <>
                      <TableRow
                        sx={{
                          fontSize: "8px !important",
                          background: index % 2 !== 0 ? "#EFF8E7" : "",
                        }}
                      >
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {index + 1}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.ref_id}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.nationality_country ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          {item?.name}
                        </TableCell>{" "}
                        <TableCell
                          colSpan={2}
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                            wordBreak: "break-word",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.total_count).toFixed(2)
                          ) ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            wordBreak: "break-word",
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.sum_15).toFixed(2)
                          ) ?? "-"}
                        </TableCell>{" "}
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                            background:
                              parseFloat(item?.sum_16_30) > 0 ? " #f3f33a" : "",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.sum_16_30).toFixed(2)
                          ) ?? "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                            background:
                              parseFloat(item?.sum_30) > 0 ? "#ee2d2d" : "",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(item?.sum_30).toFixed(2)
                          ) ?? "-"}
                        </TableCell>
                        <TableCell
                          sx={{
                            fontSize: "6px !important",
                            fontWeight: 600,
                            fontFamily: FontFamily.openSans,
                            margin: "0px 4px !important",
                            padding: "4px 8px !important",
                            textAlign: "center",
                          }}
                          className={`${classes.columnStyle}`}
                        >
                          $
                          {CommaSeparator(
                            parseFloat(
                              parseFloat(item?.sum_15) +
                                parseFloat(item?.sum_16_30) +
                                parseFloat(item?.sum_30)
                            ).toFixed(2)
                          ) ?? "-"}
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                  <TableRow>
                    <TableCell
                      sx={{
                        whiteSpace: "normal",
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        margin: "0px 4px !important",
                        padding: "4px 8px !important",
                        textAlign: "center",
                      }}
                      colSpan={tableHead?.length - 3}
                    >
                      Total DUE:
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "normal",
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        margin: "0px 4px !important",
                        padding: "4px 8px !important",
                        textAlign: "center",
                      }}
                    >
                      ${CommaSeparator(parseFloat(total15Days).toFixed(2))}
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "normal",
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        margin: "0px 4px !important",
                        padding: "4px 8px !important",
                        textAlign: "center",
                      }}
                    >
                      ${CommaSeparator(parseFloat(total16Days).toFixed(2))}
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "normal",
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        margin: "0px 4px !important",
                        padding: "4px 8px !important",
                        textAlign: "center",
                      }}
                    >
                      ${CommaSeparator(parseFloat(total30days).toFixed(2))}
                    </TableCell>
                    <TableCell
                      sx={{
                        whiteSpace: "normal",
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        margin: "0px 4px !important",
                        padding: "4px 8px !important",
                        textAlign: "center",
                      }}
                    >
                      ${CommaSeparator(parseFloat(receivableTotal).toFixed(2))}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : invoiceName === "Balance Sheet" ? (
                <BalanceSheet
                  type={type}
                  resultData={resultData}
                  libalTotal={libalTotal}
                  capitalTotal={capitalTotal}
                />
              ) : invoiceName === "Trial Balance" ? (
                <TrialBalance
                  allCredit={libalTotal}
                  allDebit={capitalTotal}
                  filters={filter}
                  type={type}
                  resultData={resultData}
                />
              ) : invoiceName === "Client Ledgers Report" ? (
                <ClientLedger resultData={resultData} />
              ) : invoiceName === "Wallet Dashboard" ? (
                <WalletDashboard resultData={resultData} />
              ) : invoiceName === "Shipping RA" ? (
                <ShippingRA resultData={resultData} />
              ) : invoiceName === "Customer Vehicle Ledger" ? (
                <CustomerVehicleLedger resultData={resultData} />
              ) : invoiceName === "Customer Shipping Ledger" ? (
                <CustomerShippingLedger resultData={resultData} />
              ) : invoiceName === "Profit or Loss Statement" ? (
                <ProfitLossStatement
                  resultData={resultData}
                  type={type}
                  capitalTotal={capitalTotal}
                  libalTotal={libalTotal}
                  filters={filter}
                  allSums={allSums}
                />
              ) : (
                ""
              )}
            </Table>
            {invoiceName === "VAT DUE Statement" ? (
              <Box
                className="bottom-box"
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  background: "#EFF8E7",
                  padding: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "8px !important",
                    fontWeight: 700,
                    fontFamily: FontFamily.openSans,
                    marginRight: "2px",
                  }}
                >
                  Total VAT DUE
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid #B2B5BA",
                    background: "white",
                    fontFamily: FontFamily.openSans,
                    padding: "4px",
                    textAlign: "center",
                    color: "black",
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "65px",
                    height: "40px",
                    fontSize: "8px !important",
                    fontWeight: 700,
                  }}
                >
                  ${CommaSeparator(parseFloat(total).toFixed(2))}
                </Typography>
              </Box>
            ) : invoiceName === "Auction House" ? (
              <Box
                className="bottom-box"
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  background: "#EFF8E7",
                  padding: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "8px !important",
                    fontWeight: 700,
                    fontFamily: FontFamily.openSans,
                    marginRight: "2px",
                  }}
                >
                  Total Remaing Due
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid #B2B5BA",
                    background: "white",
                    fontFamily: FontFamily.openSans,
                    padding: "4px",
                    textAlign: "center",
                    color: "black",
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "65px",
                    height: "40px",
                    fontSize: "8px !important",
                    fontWeight: 700,
                  }}
                >
                  ${CommaSeparator(parseFloat(total).toFixed(2))}
                </Typography>
              </Box>
            ) : invoiceName === "Vendor Payable Due Report" ? (
              <Box
                className="bottom-box"
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  background: "#EFF8E7",
                  padding: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "8px !important",
                    fontWeight: 700,
                    fontFamily: FontFamily.openSans,
                    marginRight: "2px",
                  }}
                >
                  Balance
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid #B2B5BA",
                    background: "white",
                    fontFamily: FontFamily.openSans,
                    padding: "4px",
                    textAlign: "center",
                    color: "black",
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "65px",
                    height: "40px",
                    fontSize: "8px !important",
                    fontWeight: 700,
                  }}
                >
                  ${CommaSeparator(parseFloat(total).toFixed(2))}
                </Typography>
              </Box>
            ) : invoiceName === "Shipping Statement Of Account" ? (
              <Box
                className="bottom-box"
                sx={{
                  background: "#EFF8E7",
                  padding: "8px",
                }}
              >
                <Grid
                  container
                  spacing={1}
                  alignItems="center"
                  justifyContent="space-around"
                >
                  <Grid
                    item
                    xs={3}
                    display="flex"
                    justifyContent="end"
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        marginRight: "2px",
                      }}
                    >
                      No.Of Vcc
                    </Typography>
                    <Typography
                      sx={{
                        border: "1px solid #B2B5BA",
                        background: "white",
                        fontFamily: FontFamily.openSans,
                        padding: "4px",
                        textAlign: "center",
                        color: "black",
                        borderRadius: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "65px",
                        height: "40px",
                        fontSize: "8px !important",
                        fontWeight: 700,
                      }}
                    >
                      {CommaSeparator(totalVCC)}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    display="flex"
                    justifyContent="end"
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        marginRight: "2px",
                      }}
                    >
                      Shipping Wallet
                    </Typography>
                    <Typography
                      sx={{
                        border: "1px solid #B2B5BA",
                        background: "white",
                        fontFamily: FontFamily.openSans,
                        padding: "4px",
                        textAlign: "center",
                        color: "black",
                        borderRadius: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "65px",
                        height: "40px",
                        fontSize: "8px !important",
                        fontWeight: 700,
                      }}
                    >
                      $
                      {shippingVault?.nature === "credit"
                        ? isNaN(
                            parseFloat(shippingVault?.total_credit) -
                              parseFloat(shippingVault?.total_debit)
                          )
                          ? parseFloat(0).toFixed(2)
                          : CommaSeparator(
                              parseFloat(
                               (parseFloat(shippingVault?.total_credit) -
                                  parseFloat(shippingVault?.total_debit))/currencyExchangeRate
                              ).toFixed(2)
                            )
                        : isNaN(
                            parseFloat(shippingVault?.total_debit) -
                              parseFloat(shippingVault?.total_credit)
                          )
                        ? parseFloat(0).toFixed(2)
                        : CommaSeparator(
                            parseFloat(
                              parseFloat(shippingVault?.total_debit) -
                                parseFloat(shippingVault?.total_credit)
                            ).toFixed(2)
                          )}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    display="flex"
                    justifyContent="end"
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        marginRight: "2px",
                      }}
                    >
                      Vehicle Wallet
                    </Typography>
                    <Typography
                      sx={{
                        border: "1px solid #B2B5BA",
                        background: "white",
                        fontFamily: FontFamily.openSans,
                        padding: "4px",
                        textAlign: "center",
                        color: "black",
                        borderRadius: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "75px",
                        height: "40px",
                        fontSize: "8px !important",
                        fontWeight: 700,
                      }}
                    >
                      $
                      {vehicleVault?.nature === "credit"
                        ? isNaN(
                            parseFloat(vehicleVault?.total_credit) -
                              parseFloat(vehicleVault?.total_debit)
                          )
                          ? parseFloat(0).toFixed(2)
                          : CommaSeparator(
                              parseFloat(
                                (parseFloat(vehicleVault?.total_credit) -
                                  parseFloat(vehicleVault?.total_debit))/currencyExchangeRate
                              ).toFixed(2)
                            )
                        : isNaN(
                            parseFloat(vehicleVault?.total_debit) -
                              parseFloat(vehicleVault?.total_credit)
                          )
                        ? parseFloat(0).toFixed(2)
                        : CommaSeparator(
                            parseFloat(
                              parseFloat(vehicleVault?.total_debit) -
                                parseFloat(vehicleVault?.total_credit)
                            ).toFixed(2)
                          )}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    display="flex"
                    justifyContent="end"
                    alignItems="center"
                  >
                    <Typography
                      sx={{
                        fontSize: "8px !important",
                        fontWeight: 700,
                        fontFamily: FontFamily.openSans,
                        marginRight: "2px",
                      }}
                    >
                      Total Due
                    </Typography>
                    <Typography
                      sx={{
                        border: "1px solid #B2B5BA",
                        background: "white",
                        fontFamily: FontFamily.openSans,
                        padding: "4px",
                        textAlign: "center",
                        color: "black",
                        borderRadius: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "65px",
                        height: "40px",
                        fontSize: "8px !important",
                        fontWeight: 700,
                      }}
                    >
                      $
                      {CommaSeparator(
                        parseFloat(
                          parseFloat(totalBalance) -
                            parseFloat(
                              shippingVault?.nature === "credit"
                                ? isNaN(
                                    parseFloat(shippingVault?.total_credit) -
                                      parseFloat(shippingVault?.total_debit)
                                  )
                                  ? parseFloat(0).toFixed(2)
                                  : parseFloat(
                                      parseFloat(shippingVault?.total_credit) -
                                        parseFloat(shippingVault?.total_debit)
                                    ).toFixed(2)
                                : isNaN(
                                    parseFloat(shippingVault?.total_debit) -
                                      parseFloat(shippingVault?.total_credit)
                                  )
                                ? parseFloat(0).toFixed(2)
                                : parseFloat(
                                    parseFloat(shippingVault?.total_debit) -
                                      parseFloat(shippingVault?.total_credit)
                                  ).toFixed(2)
                            )
                        ).toFixed(2)
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ) : invoiceName === "Vehicle Statement Of Account" ? (
              <>
                <Box
                  className="bottom-box"
                  sx={{
                    background: "#EFF8E7",
                    padding: "8px",
                  }}
                >
                  <Grid
                    container
                    spacing={1}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Grid item xs={2} display="flex" alignItems="center">
                      <Typography
                        sx={{
                          fontSize: "8px !important",
                          fontWeight: 700,
                          fontFamily: FontFamily.openSans,
                          marginRight: "2px",
                        }}
                      >
                        Shipping Wallet
                      </Typography>
                      <Typography
                        sx={{
                          border: "1px solid #B2B5BA",
                          background: "white",
                          fontFamily: FontFamily.openSans,
                          padding: "4px",
                          textAlign: "center",
                          color: "black",
                          borderRadius: "6px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "65px",
                          height: "40px",
                          fontSize: "8px !important",
                          fontWeight: 700,
                        }}
                      >
                        $
                        {shippingVault?.nature === "credit"
                          ? isNaN(
                              parseFloat(shippingVault?.total_credit) -
                                parseFloat(shippingVault?.total_debit)
                            )
                            ? parseFloat(0).toFixed(2)
                            : CommaSeparator(
                                parseFloat(
                                  (parseFloat(shippingVault?.total_credit) -
                                    parseFloat(shippingVault?.total_debit))/currencyExchangeRate
                                ).toFixed(2)
                              )
                          : isNaN(
                              parseFloat(shippingVault?.total_debit) -
                                parseFloat(shippingVault?.total_credit)
                            )
                          ? parseFloat(0).toFixed(2)
                          : CommaSeparator(
                              parseFloat(
                                parseFloat(shippingVault?.total_debit) -
                                  parseFloat(shippingVault?.total_credit)
                              ).toFixed(2)
                            )}
                      </Typography>
                    </Grid>

                    <Grid item xs={2} display="flex" alignItems="center">
                      <Typography
                        sx={{
                          fontSize: "8px !important",
                          fontWeight: 700,
                          fontFamily: FontFamily.openSans,
                          marginRight: "2px",
                        }}
                      >
                        Vehicle Wallet
                      </Typography>
                      <Typography
                        sx={{
                          border: "1px solid #B2B5BA",
                          background: "white",
                          fontFamily: FontFamily.openSans,
                          padding: "4px",
                          textAlign: "center",
                          color: "black",
                          borderRadius: "6px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "65px",
                          height: "40px",
                          fontSize: "8px !important",
                          fontWeight: 700,
                        }}
                      >
                        $
                        {vehicleVault?.nature === "credit"
                          ? isNaN(
                              parseFloat(vehicleVault?.total_credit) -
                                parseFloat(vehicleVault?.total_debit)
                            )
                            ? parseFloat(0).toFixed(2)
                            : CommaSeparator(
                                parseFloat(
                                  (parseFloat(vehicleVault?.total_credit) -
                                    parseFloat(vehicleVault?.total_debit))/currencyExchangeRate
                                ).toFixed(2)
                              )
                          : isNaN(
                              parseFloat(vehicleVault?.total_debit) -
                                parseFloat(vehicleVault?.total_credit)
                            )
                          ? parseFloat(0).toFixed(2)
                          : CommaSeparator(
                              parseFloat(
                                parseFloat(vehicleVault?.total_debit) -
                                  parseFloat(vehicleVault?.total_credit)
                              ).toFixed(2)
                            )}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={2.5}
                      display="flex"
                      alignItems="center"
                      justifyContent="end"
                    >
                      <Typography
                        sx={{
                          fontSize: "8px !important",
                          fontWeight: 700,
                          fontFamily: FontFamily.openSans,
                          marginRight: "2px",
                        }}
                      >
                        Due Break Up
                      </Typography>
                      <Box
                        sx={{
                          border: "1px solid #B2B5BA",
                          background: "white",
                          fontFamily: FontFamily.openSans,
                          padding: "4px",
                          textAlign: "center",
                          color: "black",
                          borderRadius: "6px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "75px",
                          height: "40px",
                          fontSize: "8px !important",
                          fontWeight: 700,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "8px !important",
                              fontWeight: 700,
                              fontFamily: FontFamily.openSans,
                            }}
                          >
                            Below 7 Days
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "8px !important",
                              fontWeight: 700,
                              fontFamily: FontFamily.openSans,
                            }}
                          >
                            ${CommaSeparator(dueBreakup?.below7DaysTotal)}{" "}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      <Box
                        sx={{
                          border: "1px solid #B2B5BA",
                          background: "white",
                          fontFamily: FontFamily.openSans,
                          padding: "4px",
                          textAlign: "center",
                          color: "black",
                          borderRadius: "6px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "75px",
                          height: "40px",
                          fontSize: "8px !important",
                          fontWeight: 700,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "8px !important",
                              fontWeight: 700,
                              fontFamily: FontFamily.openSans,
                            }}
                          >
                            8 - 14 Days{" "}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "8px !important",
                              fontWeight: 700,
                              fontFamily: FontFamily.openSans,
                            }}
                          >
                            ${CommaSeparator(dueBreakup?.below8To14DaysTotal)}{" "}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      <Box
                        sx={{
                          border: "1px solid #B2B5BA",
                          background: "white",
                          fontFamily: FontFamily.openSans,
                          padding: "4px",
                          textAlign: "center",
                          color: "black",
                          borderRadius: "6px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "75px",
                          height: "40px",
                          fontSize: "8px !important",
                          fontWeight: 700,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "8px !important",
                              fontWeight: 700,
                              fontFamily: FontFamily.openSans,
                            }}
                          >
                            Over 15 Days{" "}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "8px !important",
                              fontWeight: 700,
                              fontFamily: FontFamily.openSans,
                            }}
                          >
                            ${CommaSeparator(dueBreakup?.over15DaysTotal)}{" "}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={1} justifyContent="end">
                      <Box
                        sx={{
                          border: "1px solid #B2B5BA",
                          background: "white",
                          fontFamily: FontFamily.openSans,
                          padding: "4px",
                          textAlign: "center",
                          color: "black",
                          borderRadius: "6px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "75px",
                          height: "40px",
                          fontSize: "8px !important",
                          fontWeight: 700,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "8px !important",
                              fontWeight: 700,
                              fontFamily: FontFamily.openSans,
                            }}
                          >
                            Total
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "8px !important",
                              fontWeight: 700,
                              fontFamily: FontFamily.openSans,
                            }}
                          >
                            ${CommaSeparator(dueBreakup?.total)}{" "}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                {/* <Box
                className='bottom-box'
                  sx={{
                    padding: "4px",
                    background: "#EFF8E7",
                  }}
                >
                  <Grid
                    container
                    spacing={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-around"
                  >
                    <Grid item xs={2} justifyContent="start">
                      <Typography
                        sx={{
                          fontSize: "8px !important",
                          fontWeight: 700,
                          fontFamily: FontFamily.openSans,
                        }}
                      >
                        Due Break Up
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Box
                        sx={{
                          border: "1px solid #B2B5BA",
                          background: "white",
                          fontFamily: FontFamily.openSans,
                          padding: "4px",
                          textAlign: "center",
                          color: "black",
                          borderRadius: "6px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100px",
                          height: "40px",
                          fontSize: "8px !important",
                          fontWeight: 700,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "8px !important",
                              fontWeight: 700,      
                              fontFamily: FontFamily.openSans,
                            }}
                          >
                            Below 7 Days
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "8px !important",
                              fontWeight: 700,    
                              fontFamily: FontFamily.openSans,
                            }}
                          >
                            ${CommaSeparator(dueBreakup?.below7DaysTotal)}{" "}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Box
                        sx={{
                          border: "1px solid #B2B5BA",
                          background: "white",
                          fontFamily: FontFamily.openSans,
                          padding: "4px",
                          textAlign: "center",
                          color: "black",
                          borderRadius: "6px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100px",
                          height: "40px",
                          fontSize: "8px !important",
                          fontWeight: 700,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "8px !important",
                              fontWeight: 700,    
                              fontFamily: FontFamily.openSans,
                            }}
                          >
                            8 - 14 Days{" "}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "8px !important",
                              fontWeight: 700,   
                              fontFamily: FontFamily.openSans,
                            }}
                              
                          >
                            ${CommaSeparator(dueBreakup?.below8To14DaysTotal)}{" "}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={2}>
                      <Box
                        sx={{
                          border: "1px solid #B2B5BA",
                          background: "white",
                          fontFamily: FontFamily.openSans,
                          padding: "4px",
                          textAlign: "center",
                          color: "black",
                          borderRadius: "6px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100px",
                          height: "40px",
                          fontSize: "8px !important",
                          fontWeight: 700,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "8px !important",
                              fontWeight: 700,
                              fontFamily: FontFamily.openSans,
                            }}
                          >
                            Over 15 Days{" "}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "8px !important",
                              fontWeight: 700,  
                              fontFamily: FontFamily.openSans,
                            }}
                          >
                            ${CommaSeparator(dueBreakup?.over15DaysTotal)}{" "}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={2} justifyContent="end">
                      <Box
                        sx={{
                          border: "1px solid #B2B5BA",
                          background: "white",
                          fontFamily: FontFamily.openSans,
                          padding: "4px",
                          textAlign: "center",
                          color: "black",
                          borderRadius: "6px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "100px",
                          height: "40px",
                          fontSize: "8px !important",
                          fontWeight: 700,
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              fontSize: "8px !important",
                              fontWeight: 700,   
                              fontFamily: FontFamily.openSans,
                            }}
                          >
                            Total
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "8px !important",
                              fontWeight: 700,  
                              fontFamily: FontFamily.openSans,
                            }}
                          >
                            ${CommaSeparator(dueBreakup?.total)}{" "}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box> */}
              </>
            ) : invoiceName === "Customer Vehicle Ledger" ? (
              <Box
                className="bottom-box"
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  background: "#EFF8E7",
                  padding: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "8px !important",
                    fontWeight: 700,
                    fontFamily: FontFamily.openSans,
                    marginRight: "2px",
                  }}
                >
                  Opening Balance
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid #B2B5BA",
                    background: "white",
                    fontFamily: FontFamily.openSans,
                    padding: "4px",
                    textAlign: "center",
                    color: "black",
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "65px",
                    height: "40px",
                    fontSize: "8px !important",
                    fontWeight: 700,
                  }}
                >
                  ${CommaSeparator(parseFloat(total).toFixed(2))}{" "}
                </Typography>
              </Box>
            ) : invoiceName === "Customer Shipping Ledger" ? (
              <Box
                className="bottom-box"
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  background: "#EFF8E7",
                  padding: "8px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "8px !important",
                    fontWeight: 700,
                    fontFamily: FontFamily.openSans,
                    marginRight: "2px",
                  }}
                >
                  Opening Balance
                </Typography>
                <Typography
                  sx={{
                    border: "1px solid #B2B5BA",
                    background: "white",
                    fontFamily: FontFamily.openSans,
                    padding: "4px",
                    textAlign: "center",
                    color: "black",
                    borderRadius: "6px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "65px",
                    height: "40px",
                    fontSize: "8px !important",
                    fontWeight: 700,
                  }}
                >
                  ${CommaSeparator(parseFloat(total).toFixed(2))}{" "}
                </Typography>
              </Box>
            ) : (
              ""
            )}
          </TableContainer>
        </Box>
        {invoiceName === "Balance Sheet" ||
        invoiceName === "Trial Balance" ||
        invoiceName === "Profit or Loss Statement" ? (
          ""
        ) : (
          <Box
            className="bottom-box"
            sx={{
              marginTop: "10px",
              position: "absolute",
              bottom: 1,
              left: 1,
              right: 0,
            }}
          >
            <Box
              sx={{
                background: "#EFF8E7",
                color: "black",
                display: "flex",
                justifyContent: "start",
                minWidth: "100%",
              }}
            >
              <Box
                sx={{
                  width: " 100%",
                  padding: "2px 6px",
                  textAlign: "start",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "8px !important",
                    fontWeight: 700,
                    display: "flex",
                    justifyContent: "start",
                    fontFamily: FontFamily.openSans,
                  }}
                >
                  1- This is a system-generated statement and does not require a
                  signature. Any discrepancies in this SOA should be reported
                  within 2 days.
                </Typography>
                <Typography
                  sx={{
                    fontSize: "8px !important",
                    fontWeight: 700,
                    display: "flex",
                    justifyContent: "start",
                    fontFamily: FontFamily.openSans,
                  }}
                >
                  2- Relist payments should be made on the next day of the
                  relist. Otherwise, your account will be Locked on the second
                  day and a 15% penalty will be charged on relists.
                </Typography>
                <Typography
                  sx={{
                    fontSize: "8px !important",
                    fontWeight: 700,
                    display: "flex",
                    justifyContent: "start",
                    fontFamily: FontFamily.openSans,
                  }}
                >
                  3- Kindly settle the payment due at the earliest, we would
                  like to remind you to be in touch with our customer service
                  support team.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: " 100%",
                color: "#FFFFFF",
                padding: "1.5%",
                backgroundColor: " #5abc0e",
                textAlign: "start",
                fontSize: "8px !important",
                fontWeight: 700,
              }}
            >
              <Typography
                sx={{
                  wordBreak: "break-word",
                  fontSize: "8px !important",
                  fontWeight: 700,
                  fontFamily: FontFamily.openSans,
                }}
                className="footer-div-font"
              >
                Customer care Contact: Mohammed husni - +971523195682 (Arabic &
                English ) Ardamehr Shoev - +971545836028 (English ,Arabic, Tajik
                & Farsi) Ravin abdul kareem - +971528293801 (Kurdish , Arabic &
                English) Magsat Gylyjov - +97158666403 (Turken , Russian &
                English)
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default PDF;
