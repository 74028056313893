import { Fragment } from "react";
import {
  FormControl,
  InputLabel,
  TextField,
  Typography,
  InputAdornment,
} from "@mui/material";

function InputField(props) {
  const {
    inputRef,
    variant,
    size,
    dir,
    label,
    labelIcon,
    placeholder,
    defaultValue,
    value,
    register,
    error,
    rows,
    multiline,
    type,
    InputProps,
    disabled,
    inputProps,
    onInput,
    onBlur,
    helperText,
    style,
    id,
    inputStyle,
    startAdornment,
    endAdornment,
    custom,
    margin,
    step,
    onKeyDown
  } = props;

  return (
    <Fragment>
      <InputLabel
        error={error && true}
        sx={custom ? custom : { textTransform: "capitalize" }}
      >
        {labelIcon ? (
          <img src={labelIcon} alt="" width={"13px"} height={"13px"} />
        ) : (
          ""
        )}{" "}
        {label}
      </InputLabel>
      <FormControl
        variant="standard"
        fullWidth
        sx={{
          mt: 1,
          mb: margin ? margin : 2,
          ...style,
          ".MuiFormHelperText-root": { ml: 0 },
        }}
      >
        <TextField
          inputRef={inputRef}
          variant={variant ?? "outlined"}
          size={size}
          dir={dir}
          value={value}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
          defaultValue={defaultValue}
          id={id}
          error={error && true}
          multiline={multiline}
          rows={rows}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          InputProps={{
            startAdornment: startAdornment && (
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            ),
            endAdornment: endAdornment && (
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            ),
            ...InputProps,
          }}
          inputProps={{
            ...inputProps,
            step: step ?? "any",
          }} 
          onInput={onInput}
          helperText={helperText}
          {...register}
          sx={inputStyle}
        />
        
        <Typography color="error" sx={{ fontSize: 12, textAlign: "left" }}>
          {error}
        </Typography>
      </FormControl>
    </Fragment>
  );
}

export default InputField;
