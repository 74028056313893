import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  Grid,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
  IconButton,
  CircularProgress,
  InputAdornment,
  Dialog,
} from "@mui/material";
import styled from "@emotion/styled";
import { FontFamily, SearchIcon, DeleteIcon } from "assets";
import Colors from "assets/Style/Colors";
import { CircleLoading } from "components/Loaders";
import { ErrorToaster, SuccessToaster } from "components/Toaster";
import { Edit } from "@mui/icons-material";
import ExportServices from "services/Export";
import InputField from "components/Input";
import { useForm } from "react-hook-form";
import { PrimaryButton } from "components/Buttons";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import moment from "moment";
import { PDFExport } from "@progress/kendo-react-pdf";

// *For Table Style
const Row = styled(TableRow)(({ theme }) => ({
  border: 0,
}));

const Cell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    border: 0,
    textAlign: "center",
    whiteSpace: "nowrap",
    background: Colors.primary,
    color: Colors.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: FontFamily.NunitoRegular,
    textAlign: "center",
    textWrap: "nowrap",
    padding: "5px !important",
    ".MuiBox-root": {
      display: "flex",
      gap: "6px",
      alignItems: "center",
      justifyContent: "center",
    },
    svg: {
      width: "auto",
      height: "24px",
    },
    ".MuiTypography-root": {
      textTransform: "capitalize",
      fontFamily: FontFamily.NunitoRegular,
    },
  },
}));

function ExportCountry() {
  const contentRef = useRef(null);
  const { register, handleSubmit, getValues, formState: { errors } } = useForm();
  const [countries, setCountries] = useState([]);
  const [originalCountries, setOriginalCountries] = useState([]);
  const [loader, setLoader] = useState(false);
  const [countryDialog, setCountryDialog] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const tableHead = ["Country", "Action"];

  // Fetch countries
  const getExportCountries = async () => {
    setLoader(true);
    try {
      const { data } = await ExportServices.getExportCountries();
      setCountries(data?.countries || []);
      setOriginalCountries(data?.countries || []);
    } catch (error) {
      ErrorToaster(error);
    } finally {
      setLoader(false);
    }
  };

  // Create new country
  const createExportCountry = async () => {
    try {
      const { data } = await ExportServices.CreateExportCountry({
        name: getValues("countryName"),
      });
      setCountryDialog(false);
      SuccessToaster(data?.message);
      getExportCountries();
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // Delete a country
  const handleDelete = async (id) => {
    try {
      const { data } = await ExportServices.DeleteExportCountry({ id: id });
      setOpenDeleteModal(false);
      SuccessToaster(data?.message);
      getExportCountries();
    } catch (error) {
      ErrorToaster(error);
    }
  };

  // Search Functionality
  const handleSearch = (value) => {
    if (value) {
      const filteredCountries = originalCountries.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setCountries(filteredCountries);
    } else {
      setCountries(originalCountries);
    }
  };

  // Download Excel
  const downloadExcel = () => {
    const headers = tableHead;
    const rows = countries?.map((item) => [item?.name ?? "-"]);

    const ws = XLSX.utils.aoa_to_sheet([headers, ...rows]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    const buf = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
      mimeType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    saveAs(new Blob([buf]), "ExportCountries.xlsx");
  };

  useEffect(() => {
    getExportCountries();
  }, []);

  return (
    <Box sx={{ m: 4, mb: 2 }}>
      {/* Add Country Dialog */}
      <Dialog open={countryDialog} onClose={() => setCountryDialog(false)}>
        <Box component="form" onSubmit={handleSubmit(createExportCountry)}>
          <Grid container spacing={2} sx={{ p: 3 }}>
            <Grid item xs={12}>
              <InputField
                size={"small"}
                label={"Country Name"}
                placeholder={"Country Name"}
                register={register("countryName", {
                  required: "Please enter country name.",
                })}
                error={errors?.countryName?.message}
              />
            </Grid>
            <Grid item xs={12} sx={{ mt: 2, textAlign: "right" }}>
              <PrimaryButton title="Submit" type="submit" />
            </Grid>
          </Grid>
        </Box>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography
          sx={{
            p: 3,
            px: 5,
            fontSize: 17,
            fontFamily: FontFamily.openSans,
            fontWeight: "bold",
          }}
          id="alert-dialog-title"
        >
          Are you sure you want to delete this country?
        </Typography>
        <Grid item xs={12} sm={12} sx={{ mb: 2, textAlign: "center" }}>
          <PrimaryButton
            title="No"
            type="button"
            buttonStyle={{ minWidth: "120px" }}
            onClick={() => setOpenDeleteModal(false)}
          />
          <Box component={"span"} sx={{ mx: 1 }} />
          <PrimaryButton
            title="Yes"
            type="button"
            buttonStyle={{
              minWidth: "120px",
              bgcolor: Colors.danger,
              "&:hover": { bgcolor: Colors.danger },
            }}
            bgcolor={Colors.danger}
            onClick={() => handleDelete(selectedItemId)}
          />
        </Grid>
      </Dialog>

      {/* Header and Actions */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 4,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: Colors.charcoalGrey,
            fontFamily: FontFamily.NunitoRegular,
          }}
        >
          Export Countries
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "20px",
          }}
        >
          {countries?.length > 0 && (
            <PrimaryButton title={"Download Excel"} onClick={downloadExcel} />
          )}
          <PrimaryButton
            title="Add Country"
            onClick={() => setCountryDialog(true)}
          />
        </Box>
      </Box>

      {/* Search Field */}
      <Box sx={{ mb: 2, width: "50%", maxWidth: "300px" }}>
        <InputField
          size="small"
          placeholder="Search Country"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => handleSearch(e.target.value)}
          inputStyle={{
            height: "35px",
            fontSize: "12px",
            padding: "5px 10px",
          }}
        />
      </Box>


      {/* Table */}
      <TableContainer component={Paper}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {tableHead.map((item, index) => (
                <Cell key={index}>{item}</Cell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!loader ? (
              countries?.length > 0 ? (
                countries?.map((item, index) => (
                  <Row key={index}>
                    <Cell>{item?.name ?? "-"}</Cell>
                    <Cell>
                      <IconButton
                        onClick={() => {
                          setSelectedItemId(item.id);
                          setOpenDeleteModal(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Cell>
                  </Row>
                ))
              ) : (
                <Row>
                  <Cell colSpan={tableHead.length} align="center">
                    No Data Found
                  </Cell>
                </Row>
              )
            ) : (
              <Row>
                <Cell colSpan={tableHead.length} align="center">
                  <CircularProgress />
                </Cell>
              </Row>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default ExportCountry;
